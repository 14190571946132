'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _d = require('d3');

var _d2 = _interopRequireDefault(_d);

var _reactMotion = require('react-motion');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var BarChart = function (_Component) {
    _inherits(BarChart, _Component);

    function BarChart() {
        _classCallCheck(this, BarChart);

        return _possibleConstructorReturn(this, Object.getPrototypeOf(BarChart).apply(this, arguments));
    }

    _createClass(BarChart, [{
        key: 'willLeave',
        value: function willLeave(_ref) {
            var style = _ref.style;


            return _extends({}, style, {
                width: 0,
                x: style.x + style.width
            });
        }
    }, {
        key: 'willEnter',
        value: function willEnter(_ref2) {
            var style = _ref2.style;

            return _extends({}, style, {
                width: 0,
                x: style.x + style.width
            });
        }
    }, {
        key: 'render',
        value: function render() {
            var _context = this.context;
            var xScale = _context.xScale;
            var yScale = _context.yScale;
            var data = _context.data;
            var _context$dataTransfor = _context.dataTransform;
            var dataTransform = _context$dataTransfor === undefined ? function (arr) {
                return arr.map(function (d, i) {
                    return {
                        x: i,
                        dx: 0,
                        y0: 0,
                        y: d,
                        z: 0,
                        index: i
                    };
                });
            } : _context$dataTransfor;
            var _context$groupWidth = _context.groupWidth;
            var groupWidth = _context$groupWidth === undefined ? 1 : _context$groupWidth;
            var _props = this.props;
            var style = _props.style;
            var _onClick = _props.onClick;


            var txData = dataTransform(data);

            var xScaleFn = xScale.domain(_d2.default.range(data.length));

            var yScaleFn = yScale.domain([0, _d2.default.max(txData, function (d) {
                return d.y0 + d.y;
            })]);

            var motionStyles = txData.map(function (d) {
                return {
                    key: d.index + '-' + d.z,
                    data: _extends({}, d, { index: d.index }),
                    style: {
                        width: xScaleFn.rangeBand() / groupWidth,
                        height: yScaleFn(d.y0) - yScaleFn(d.y0 + d.y),
                        y0: yScaleFn(d.y0),
                        y: yScaleFn(d.y0 + d.y),
                        x: xScaleFn(d.x) + xScaleFn.rangeBand() / groupWidth * d.dx

                    }
                };
            });

            var defaultStyles = txData.map(function (d) {
                return {
                    key: d.index + '-' + d.z,
                    data: _extends({}, d, { index: d.index }),
                    style: {
                        width: xScaleFn.rangeBand() / groupWidth,
                        height: 0,
                        y0: yScaleFn(d.y0),
                        y: yScaleFn(d.y0),
                        x: xScaleFn(d.x) + xScaleFn.rangeBand() / groupWidth * d.dx
                    }
                };
            });

            return _react2.default.createElement(
                'g',
                { className: 'bar-chart' },
                _react2.default.createElement(
                    _reactMotion.TransitionMotion,
                    { defaultStyles: defaultStyles, styles: motionStyles,
                        willEnter: this.willEnter.bind(this), willLeave: this.willLeave.bind(this) },
                    function (interStyles) {
                        return _react2.default.createElement(
                            'g',
                            { className: 'bars' },
                            interStyles.map(function (config) {
                                return _react2.default.createElement(
                                    _reactMotion.Motion,
                                    { defaultStyle: _extends({}, config.style, {
                                            height: 0,
                                            y: config.style.y
                                        }),
                                        key: config.key,
                                        style: _extends({}, config.style, {
                                            height: (0, _reactMotion.spring)(config.style.height),
                                            y: (0, _reactMotion.spring)(config.style.y),
                                            x: (0, _reactMotion.spring)(config.style.x),
                                            width: (0, _reactMotion.spring)(config.style.width)
                                        }) },
                                    function (interStyle) {

                                        return _react2.default.createElement('rect', { className: 'bar', width: interStyle.width,
                                            height: interStyle.height,
                                            onClick: function onClick(e) {
                                                _onClick(e, config.data.y, config.data.index);
                                            },
                                            x: interStyle.x, y: interStyle.y,
                                            style: style(config.data.y, config.data.index) });
                                    }
                                );
                            })
                        );
                    }
                ),
                this.props.children
            );
        }
    }]);

    return BarChart;
}(_react.Component);

BarChart.displayName = 'BarChart';
BarChart.propTypes = {
    style: _react.PropTypes.func,
    onClick: _react.PropTypes.func
};
BarChart.contextTypes = {
    data: _react.PropTypes.oneOfType([_react.PropTypes.arrayOf(_react.PropTypes.number), _react.PropTypes.arrayOf(_react.PropTypes.arrayOf(_react.PropTypes.number))]),
    height: _react.PropTypes.number.isRequired,
    width: _react.PropTypes.number.isRequired,
    margin: _react.PropTypes.shape({
        top: _react.PropTypes.number,
        bottom: _react.PropTypes.number,
        left: _react.PropTypes.number,
        right: _react.PropTypes.number
    }),
    xScale: _react.PropTypes.func.isRequired,
    yScale: _react.PropTypes.func.isRequired,
    dataTransform: _react.PropTypes.func,
    groupWidth: _react.PropTypes.number
};
BarChart.defaultProps = {
    style: function style() {
        return {};
    },
    onClick: function onClick() {}
};
exports.default = BarChart;