'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var Group = function (_Component) {
    _inherits(Group, _Component);

    function Group() {
        _classCallCheck(this, Group);

        return _possibleConstructorReturn(this, Object.getPrototypeOf(Group).apply(this, arguments));
    }

    _createClass(Group, [{
        key: 'getChildContext',
        value: function getChildContext() {
            var data = this.context.data;


            return _extends({}, this.context, {
                groupWidth: data[0].length,
                dataTransform: this.dataTransform
            });
        }
    }, {
        key: 'dataTransform',
        value: function dataTransform(arr2d) {

            var txData = [];

            arr2d.forEach(function (samples, i) {
                txData.push(samples.map(function (sample, j) {
                    return {
                        x: i,
                        dx: j,
                        y: sample,
                        z: i,
                        y0: 0,
                        index: j
                    };
                }));
            });

            return Array.prototype.concat.apply([], txData);
        }
    }, {
        key: 'render',
        value: function render() {
            return _react2.default.createElement(
                'g',
                { className: 'stack' },
                this.props.children
            );
        }
    }]);

    return Group;
}(_react.Component);

Group.displayName = 'Group';
Group.contextTypes = {
    data: _react.PropTypes.arrayOf(_react.PropTypes.arrayOf(_react.PropTypes.number)).isRequired,
    height: _react.PropTypes.number.isRequired,
    width: _react.PropTypes.number.isRequired,
    margin: _react.PropTypes.shape({
        top: _react.PropTypes.number,
        bottom: _react.PropTypes.number,
        left: _react.PropTypes.number,
        right: _react.PropTypes.number
    }),
    xScale: _react.PropTypes.func.isRequired,
    yScale: _react.PropTypes.func.isRequired
};
Group.childContextTypes = {
    data: _react.PropTypes.arrayOf(_react.PropTypes.arrayOf(_react.PropTypes.number)),
    height: _react.PropTypes.number,
    width: _react.PropTypes.number,
    margin: _react.PropTypes.shape({
        top: _react.PropTypes.number,
        bottom: _react.PropTypes.number,
        left: _react.PropTypes.number,
        right: _react.PropTypes.number
    }),
    xScale: _react.PropTypes.func.isRequired,
    yScale: _react.PropTypes.func.isRequired,
    dataTransform: _react.PropTypes.func,
    groupWidth: _react.PropTypes.number
};
exports.default = Group;