'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.Axis = undefined;

var _Colors = require('./Colors');

var Colors = _interopRequireWildcard(_Colors);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

var Axis = exports.Axis = {
    text: {
        fontSize: '0.8em'
    },
    paths: {
        fill: 'none',
        stroke: Colors.AXIS_LINES,
        shapeRendering: 'crispEdges'
    },
    lines: {
        fill: 'none',
        stroke: Colors.AXIS_LINES,
        shapeRendering: 'crispEdges'
    }
};