'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styles = require('./styles');

var _d = require('d3');

var _d2 = _interopRequireDefault(_d);

var _scales = require('./helpers/scales');

var _axes = require('./helpers/axes');

var _reactMotion = require('react-motion');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var YAxis = function (_Component) {
    _inherits(YAxis, _Component);

    function YAxis(props) {
        _classCallCheck(this, YAxis);

        var _this = _possibleConstructorReturn(this, Object.getPrototypeOf(YAxis).call(this, props));

        _this.state = {};
        return _this;
    }

    _createClass(YAxis, [{
        key: 'willEnter',
        value: function willEnter(_ref) {
            var style = _ref.style;

            return _extends({}, style, {
                opacity: 0
            });
        }
    }, {
        key: 'willLeave',
        value: function willLeave(_ref2) {
            var style = _ref2.style;

            return _extends({}, style, {
                opacity: 0
            });
        }
    }, {
        key: 'render',
        value: function render() {
            var _props =
            /*eslint-disable no-unused-vars*/

            /*eslint-enable unused*/
            this.props;
            var orientation = _props.orientation;
            var outerTickSize = _props.outerTickSize;
            var innerTickSize = _props.innerTickSize;
            var tickValues = _props.tickValues;
            var tickPadding = _props.tickPadding;
            var ticks = _props.ticks;
            var tickFormat = _props.tickFormat;
            var tickTextStyle = _props.tickTextStyle;
            var tickLineStyle = _props.tickLineStyle;
            var textRotation = _props.textRotation;
            var debug = _props.debug;
            var _context = this.context;
            var yScale = _context.yScale;
            var data = _context.data;
            var width = _context.width;
            var height = _context.height;
            var margin = _context.margin;
            var _context$dataTransfor = _context.dataTransform;
            var dataTransform = _context$dataTransfor === undefined ? function (arr) {
                return arr.map(function (d, i) {
                    return {
                        x: i,
                        y0: 0,
                        y: d,
                        z: 0
                    };
                });
            } : _context$dataTransfor;


            var txData = dataTransform(data);
            var tickSpacing = Math.max(innerTickSize, 0) + tickPadding;
            var wMax = width - margin.left - margin.right;
            var hMax = height - margin.top - margin.bottom;

            var scale = yScale.domain([0, _d2.default.max(txData, function (d) {
                return d.y0 + d.y;
            })]).copy();

            var ticksArr = (0, _axes.getTicks)(scale, tickValues, ticks);

            var displacement = orientation === 'right' ? 'translate(' + wMax + ',0)' : 'translate(0, 0)';

            var sign = orientation === 'left' ? -1 : 1;

            var range = (0, _scales.scaleRange)(scale);
            var pathD = 'M' + sign * outerTickSize + ',' + range[0] + 'H0V' + range[1] + 'H' + sign * outerTickSize;

            var dx = 0;
            if (scale.rangeBand) {
                dx = scale.rangeBand() / 2;
            }

            var defaultStyles = ticksArr.map(function (t, i) {
                return {
                    key: i + '',
                    data: {
                        text: tickFormat(t, i)
                    },
                    style: {
                        ty: scale(t) + dx,
                        tx: 0,
                        textRotation: textRotation,
                        opacity: 1
                    }
                };
            });

            var motionStyles = ticksArr.map(function (t, i) {
                return {
                    key: i + '',
                    data: {
                        text: tickFormat(t, i)
                    },
                    style: {
                        ty: scale(t) + dx,
                        tx: 0,
                        textRotation: textRotation,
                        opacity: 1
                    }
                };
            });

            return _react2.default.createElement(
                'g',
                { className: 'axis', transform: displacement },
                _react2.default.createElement(
                    _reactMotion.TransitionMotion,
                    { defaultStyles: defaultStyles, styles: motionStyles,
                        willEnter: this.willEnter.bind(this), willLeave: this.willLeave.bind(this) },
                    function (interStyles) {
                        return _react2.default.createElement(
                            'g',
                            { className: 'ticks' },
                            interStyles.map(function (config) {
                                return _react2.default.createElement(
                                    _reactMotion.Motion,
                                    { defaultStyle: _extends({}, config.style, {
                                            opacity: 1
                                        }),
                                        key: config.key,
                                        style: _extends({}, config.style, {
                                            tx: (0, _reactMotion.spring)(config.style.tx),
                                            ty: (0, _reactMotion.spring)(config.style.ty),
                                            textRotation: (0, _reactMotion.spring)(config.style.textRotation),
                                            opacity: (0, _reactMotion.spring)(config.style.opacity)
                                        }) },
                                    function (interStyle) {
                                        return _react2.default.createElement(
                                            'g',
                                            { className: 'tick',
                                                transform: 'translate(' + interStyle.tx + ',' + interStyle.ty + ')',
                                                style: {
                                                    opacity: interStyle.opacity
                                                } },
                                            _react2.default.createElement('line', { x2: sign * innerTickSize,
                                                style: _extends({}, _styles.Axis.lines, tickLineStyle) }),
                                            _react2.default.createElement(
                                                'text',
                                                {
                                                    y: 0,
                                                    x: sign * tickSpacing,
                                                    dy: '.32em',
                                                    transform: 'rotate(' + interStyle.textRotation + ',0,0)',
                                                    style: _extends({
                                                        textAnchor: sign < 0 ? 'end' : 'start'
                                                    }, _styles.Axis.text, tickTextStyle) },
                                                config.data.text
                                            )
                                        );
                                    }
                                );
                            })
                        );
                    }
                ),
                _react2.default.createElement('path', { className: 'domain', style: _styles.Axis.paths, d: pathD })
            );
        }
    }]);

    return YAxis;
}(_react.Component);

YAxis.displayName = 'YAxis';
YAxis.contextTypes = {
    yScale: _react.PropTypes.func.isRequired,
    dataTransform: _react.PropTypes.func,
    data: _react.PropTypes.oneOfType([_react.PropTypes.arrayOf(_react.PropTypes.number), _react.PropTypes.arrayOf(_react.PropTypes.arrayOf(_react.PropTypes.number))]),
    height: _react.PropTypes.number,
    width: _react.PropTypes.number,
    margin: _react.PropTypes.shape({
        top: _react.PropTypes.number,
        bottom: _react.PropTypes.number,
        left: _react.PropTypes.number,
        right: _react.PropTypes.number
    })
};
YAxis.propTypes = {

    labels: _react.PropTypes.func,
    orientation: _react.PropTypes.oneOf(['left', 'right']),
    tickFormat: _react.PropTypes.func,
    outerTickSize: _react.PropTypes.number,
    innerTickSize: _react.PropTypes.number,
    tickPadding: _react.PropTypes.number,
    tickValues: _react.PropTypes.arrayOf(_react.PropTypes.any),
    ticks: _react.PropTypes.arrayOf(_react.PropTypes.any),
    tickTextStyle: _react.PropTypes.object,
    tickLineStyle: _react.PropTypes.object,
    textRotation: _react.PropTypes.number,
    debug: _react.PropTypes.bool
};
YAxis.defaultProps = {
    tickFormat: function tickFormat(v) {
        return v;
    },
    labels: function labels(v) {
        return v;
    },
    orientation: 'left',
    outerTickSize: 6,
    innerTickSize: 6,
    tickPadding: 3,
    tickValues: null,
    ticks: [10],
    tickTextStyle: {},
    tickLineStyle: {},
    textRotation: 0
};
exports.default = YAxis;