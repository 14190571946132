"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.getTicks = getTicks;

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function getTicks(scale, tickValues, ticks) {
    if (tickValues === null) {
        return scale.ticks ? scale.ticks.apply(scale, _toConsumableArray(ticks)) : scale.domain();
    } else {
        return tickValues;
    }
}