"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.scaleExtent = scaleExtent;
exports.scaleRange = scaleRange;
function scaleExtent(domain) {
    var start = domain[0],
        stop = domain[domain.length - 1];
    return start < stop ? [start, stop] : [stop, start];
}

function scaleRange(scale) {
    return scale.rangeExtent ? scale.rangeExtent() : scaleExtent(scale.range());
}