'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _d = require('d3');

var _d2 = _interopRequireDefault(_d);

var _reactMotion = require('react-motion');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var Pie = function (_Component) {
    _inherits(Pie, _Component);

    function Pie() {
        _classCallCheck(this, Pie);

        return _possibleConstructorReturn(this, Object.getPrototypeOf(Pie).apply(this, arguments));
    }

    _createClass(Pie, [{
        key: 'willLeave',
        value: function willLeave(_ref) {
            var style = _ref.style;

            return _extends({}, style, {
                startAngle: style.endAngle
            });
        }
    }, {
        key: 'willEnter',
        value: function willEnter(_ref2) {
            var style = _ref2.style;

            return _extends({}, style, {
                endAngle: style.startAngle
            });
        }
    }, {
        key: 'render',
        value: function render() {
            var _context = this.context;
            var width = _context.width;
            var height = _context.height;
            var data = _context.data;

            var pieFn = _d2.default.layout.pie().sort(null);
            var _props = this.props;
            var style = _props.style;
            var _onClick = _props.onClick;


            var arcFn = _d2.default.svg.arc().outerRadius(this.props.outerRadius).innerRadius(this.props.innerRadius);

            var pieData = pieFn(data);
            var motionStyles = pieData.map(function (d, i) {
                return {
                    key: i + '',
                    data: _extends({}, d, { index: i }),
                    style: d
                };
            });

            var defaultStyles = pieData.map(function (d, i) {
                return {
                    key: i + '',
                    data: _extends({}, d, { index: i }),
                    style: _extends({}, d, { endAngle: d.startAngle })
                };
            });

            var centerTransform = 'translate(' + width / 2 + ', ' + height / 2 + ')';

            return _react2.default.createElement(
                'g',
                { className: 'pie', transform: centerTransform },
                _react2.default.createElement(
                    _reactMotion.TransitionMotion,
                    { defaultStyles: defaultStyles, styles: motionStyles,
                        willEnter: this.willEnter, willLeave: this.willLeave },
                    function (interStyles) {
                        return _react2.default.createElement(
                            'g',
                            { className: 'slices' },
                            interStyles.map(function (config) {
                                return _react2.default.createElement(
                                    _reactMotion.Motion,
                                    { defaultStyle: _extends({}, config.style, {
                                            endAngle: config.data.startAngle
                                        }),
                                        key: config.key,
                                        style: _extends({}, config.style, {
                                            startAngle: (0, _reactMotion.spring)(config.style.startAngle),
                                            endAngle: (0, _reactMotion.spring)(config.style.endAngle)
                                        }) },
                                    function (interStyle) {
                                        return _react2.default.createElement('path', { d: arcFn(interStyle),
                                            style: style(config.data.value, config.data.index),
                                            onClick: function onClick(e) {
                                                _onClick(e, config.data.value, config.data.index);
                                            } });
                                    }
                                );
                            })
                        );
                    }
                ),
                this.props.children
            );
        }
    }]);

    return Pie;
}(_react.Component);

Pie.displayName = 'Pie';
Pie.propTypes = {
    innerRadius: _react.PropTypes.number,
    outerRadius: _react.PropTypes.number,
    style: _react.PropTypes.func,
    onClick: _react.PropTypes.func
};
Pie.contextTypes = {
    data: _react.PropTypes.arrayOf(_react.PropTypes.number),
    height: _react.PropTypes.number,
    width: _react.PropTypes.number,
    margin: _react.PropTypes.shape({
        top: _react.PropTypes.number,
        bottom: _react.PropTypes.number,
        left: _react.PropTypes.number,
        right: _react.PropTypes.number
    })
};
Pie.defaultProps = {
    style: function style() {
        return {};
    },
    onClick: function onClick() {}
};
exports.default = Pie;